import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import { useState, useEffect, useCallback } from 'react';

import './CustomModal.css';

let names = ['Loading'];

function LoadingModal(props) {
    const [newName, setnewName] = useState("Loading");
    let index = 0;

    if (window.location.href.includes('calculator')) {
        names = ['Calculating', 'Optimizing'];
    }

    const shuffle = useCallback(() => {
        setnewName(names[index]);
        if (index < names.length) {
            index = index + 1;
        } else {
            index = 0;
        }
    }, []);

    useEffect(() => {
        const intervalID = setInterval(shuffle, 2000);
        return () => clearInterval(intervalID);
    }, [shuffle])
    
    return (
        <Modal
            {...props}
            size="lg"
            centered>
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            {props.rotateNames ? (
                <h4 className='modal-heading'>{newName || 'Loading'}, please wait....</h4>) :
                (<h4 className='modal-heading'>{'Loading'}, please wait....</h4>)}
            <Modal.Body>
                <Spinner animation='border' variant='success' role='status'>
                </Spinner>
            </Modal.Body>
        </Modal>
    );
}

export default LoadingModal;