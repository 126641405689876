/* eslint-disable no-prototype-builtins */
import React from 'react';
import moment from "moment-timezone";
import { Button } from "react-bootstrap";
import { toast, ToastContainer } from 'react-toastify';

function dateFormatter(cell, row) {
  return <span>{moment(cell).tz('America/New_York').format('MM/DD/YYYY hh:mm')} EST</span>
}

function termFormatter(cell, row) {
  return <span>{cell}</span>
}

function quoteNumberFormatter(cell, row) {
  if (row['is_manual__c']) {
    return <span>{cell} (Upload)</span>
  }
  return <span>{cell}</span>
}

function statusFormatter(cell, row) {
  let variant = 'secondary';
  if (row && ['Rejected', 'Denied'].includes(row.Status)) {
    variant = 'danger';
  } else if (['Approved', 'Accepted'].includes(row.Status)) {
    variant = 'success';
  }
  return (
    <Button variant={variant} disabled size='sm'>
      {row.Status}
    </Button>
  )
}

export const columns = [{
  dataField: 'QuoteNumber',
  text: 'Quote #',
  formatter: quoteNumberFormatter
},
{
  dataField: 'CreatedDate',
  text: 'Date Created (sort)',
  formatter: dateFormatter,
  sort: true
}, {
  dataField: 'Owner.Name',
  text: 'Sales Owner (sort)',
  sort: true
}, 
{
  dataField: 'financing_type__c',
  text: 'Financing Type (sort)',
  sort: true
}, 
{
  dataField: 'ppa_term_yrs_num__c',
  text: 'PPA Term (years)',
  formatter: termFormatter,
  sort: true
}, 
{
  dataField: 'lease_term_yrs_num__c',
  text: 'Lease Term (years)',
  formatter: termFormatter,
  sort: true
}, 
{
  dataField: 'Status',
  text: 'Status (sort)',
  formatter: statusFormatter,
  sort: true
}];

export function skipNestedAttributes(obj) {
  var result = {};

  Object.keys(obj).forEach(key => {
    if (obj.hasOwnProperty(key) && typeof obj[key] !== "object") {
      result[key] = obj[key];
    }
  });

  return result;
}

export const config = {
  headers: {
    'Access-Control-Allow-Origin': '*',
    "Access-Control-Allow-Methods": "*",
    "Access-Control-Allow-Headers": "*",
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true
  },
  credentials: "same-origin",
};

export function renderToast() {
  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
    />
  );
}

export const currencyFormatter = (value) =>
  new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 3
  }).format(value);