import { create } from 'zustand'

interface OpportunitySearchStore {
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
  openOpportunityId: string;
  setOpenOpportunityId: (openOpportunityId: string) => void;
  openOpportunityTab: string;
  setOpenOpportunityTab: (openOpportunityTab: string) => void;
}

const useOpportunitySearchStore = create<OpportunitySearchStore>()(
  (set) => ({
    searchTerm: '',
    openOpportunityId: '',
    openOpportunityTab: '',
    setOpenOpportunityTab: (openOpportunityTab) => set(() => ({ openOpportunityTab })),
    setOpenOpportunityId: (openOpportunityId) => set(() => ({ openOpportunityId })),
    setSearchTerm: (search) => set(() => ({ searchTerm: search })),
  })
)

export default useOpportunitySearchStore;