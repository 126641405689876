import React from "react";
import './Footer.css';
import { Button, Row, Col } from "react-bootstrap";


class Footer extends React.Component {
    render() {
        return (
            <div className="footer">
                <Row className="justify-content-md-center" xs="auto">
                    <Col>
                        <Button variant="link" href="https://www.sunrockdg.com/privacy-policy" target="_blank">Privacy Policy</Button>
                    </Col>
                    <Col>
                        <p>{'\u2B24'}</p>
                    </Col>
                    <Col>
                        <Button variant="link" href="https://www.sunrockdg.com/terms-of-use" target="_blank">Terms of Use</Button>
                    </Col>
                    <Col>
                        <p>{'\u2B24'}</p>
                    </Col>
                    <Col>
                        <Button variant="link" href="https://www.sunrockdg.com/#contact" target="_blank">Contact</Button></Col>
                    <Col>
                        <p>{'\u2B24'}</p>
                    </Col>
                    <Col>
                        <Button variant="link" href="https://www.sunrockdg.com/" target="_blank">ⓒ&nbsp; Sunrock</Button>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Footer;
