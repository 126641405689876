/* eslint-disable @typescript-eslint/no-explicit-any */
import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import callApiJson from './callApi';

const useOpportunityQuotes = (selectedOpportunityId: string) => useQuery({
  queryKey: ["opportunityQuotes", selectedOpportunityId],
  queryFn: async () => {
    try {
      const data = await callApiJson("/api/quotes?opportunityId=" + selectedOpportunityId);
      const result = data.filter((x: any) => x.OpportunityId === selectedOpportunityId);
      return {
        applicationId: selectedOpportunityId,
        quotes: result
      };
    } catch (error) {
      console.log(error);
      toast.warning('Failed to retrieve sales quotes, retry', {
        position: toast.POSITION.TOP_RIGHT
      });
      throw new Error("Failed to retrieve opportunity quotes");
    }
  },
  enabled: !!selectedOpportunityId,
})

export default useOpportunityQuotes;
