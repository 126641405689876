/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useMemo, useState } from 'react';
import {  Container } from 'react-bootstrap';
import { Button } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper, Link, useTheme } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import CsvDownloadButton from 'react-json-to-csv';
import { currencyFormatter } from '../../constants';
import { outputsConstants } from './helpers';
import { 
  shouldRender, 
  usesSchema, 
  getDecimalPlaces, 
  getFieldDefinition
} from '../../providers/FieldConfigUtils';
import {useJsonData} from '../../providers/FieldConfigProvider.jsx';

import './Outputs.css';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { Box } from '@mui/material';
import useCalculatorOutputs from '../../api/useCalculatorOutputs';
import callApiJson from '../../api/callApi';

function Outputs({
  outputs,  
  action,
  businessName,
  projectId,
  quoteData: selectedQuoteData,
  systemDesignData,
  selectedSystemDesign,
  opportunityData,
  handleSave,
  selectedFinancingType,
  showOutputs,
  onOutputsChange,
  isView,
  calculatorVersion,
  opportunityId,
  outputDestination,
}: {
  outputs: any,
  action: string,
  businessName: string,
  projectId: string,
  quoteData: any,
  systemDesignData: any,
  selectedSystemDesign: string,
  opportunityData: any,
  handleSave: () => void,
  selectedFinancingType: string,
  showOutputs: boolean,
  onOutputsChange: (outputs: any) => void,
  isView: boolean,
  calculatorVersion: string,
  opportunityId: string,
  outputDestination: any,
}) {
  const theme = useTheme();
  const { data: prevOutputs, isLoading: loadingOutputs } = useCalculatorOutputs(calculatorVersion, isView, opportunityId + selectedSystemDesign);
  const [tab, setTab] = useState("1");
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const sunrockConfig = useJsonData();

  if (!loadingOutputs && prevOutputs && prevOutputs?.length > 0 && isView) {
    onOutputsChange(prevOutputs);
  }

  function formatValue(output_field_dict) {
    const outputSettings = outputsConstants.find(x => x.data_variable_element === output_field_dict.data_variable_element);
    let isNeg = false;

    if (output_field_dict && output_field_dict.value !== null && outputSettings && 
      output_field_dict.value !== undefined) {
      if (parseFloat(output_field_dict.value) < 0) {
        isNeg = true;
      }
      if (outputSettings.value_type?.toLowerCase().includes('currency')) {
        output_field_dict['value'] = currencyFormatter(output_field_dict.value);
      } else if (outputSettings.value_type?.toLowerCase().includes('percent')) {
        output_field_dict['value'] = 
        (Math.round(parseFloat(output_field_dict.value) * 10000) / 100).toString() + '%';
      } else if (outputSettings.value_type?.toLowerCase().includes('decimal')) {
        
        const decimalPlaces = getDecimalPlaces(outputSettings.value_type) 
        output_field_dict.value = parseFloat(output_field_dict.value).toLocaleString(
          'en-US', 
          {
            minimumFractionDigits:decimalPlaces, 
            maximumFractionDigits:decimalPlaces
          }
        )
      } else {
        output_field_dict['value'] = output_field_dict.value.toString();
      }
      if (isNeg) {
        // format negative with brackets
        output_field_dict['value'] = '(' + output_field_dict['value'].substring(1) + ')';
      } 
    }
  }

  const handleDownloadAWSClicked = async () => {
    let url: string;
    if (outputDestination === undefined || action === 'view') {
      url = '/api/model?quote_id=' + selectedQuoteData.Id;
    }
    else {
      const filename = outputDestination.replace(/^.*[\\\/]/, '');
      url = '/api/model?filename=' + filename;
    }
    const responseJson = await callApiJson(url);
    if (responseJson.status == "success") {
      const responseUrl = responseJson.url;
      window.open(responseUrl);
    }
    else {
      console.log(responseJson.error);
    }
  }

  const formatOutput = (output) => {
    // Review this
    //   "opp_maintain_cost_escal__c": 50.0,
    if (output.data_variable_element === 'sr_finl_dev_fee_per_watt_out') {
      console.log(output);
    }
    if (usesSchema(sunrockConfig, output.data_variable_element)) {

      if (!output.value) {
        return "";
      }

      // if configured as escalator, get label.
      const fieldDefinition = getFieldDefinition(sunrockConfig, output.data_variable_element);
      if (fieldDefinition.isEscalator) {
        return (parseFloat(output.value) * 100).toFixed(2).toString() + '%'
      }
    }

    return output.value;
  }

  const renderOutputs = useMemo(() => {
    const result: ReactJSXElement[] = [];
    if (outputs && outputs.length > 0) {
      let counter = 0;
      let outputsToDisplay = outputs.map(x => {
        const outputSettings = outputsConstants.find(y => y.data_variable_element === x.data_variable_element);
        if (outputSettings) {
          x.ui_name = outputSettings.ui_name;
          x.pw_system_quote_and_sd_order = outputSettings.pw_system_quote_and_sd_order;
          x.pw_quote_output_only_order = outputSettings.pw_quote_output_only_order;
          x.root = outputSettings.root;
        };
        return x;
      });
      // quote + system design flow
      if (systemDesignData !== undefined && systemDesignData !== null && selectedSystemDesign) {
        outputsToDisplay = outputs.filter(x => {
          const outputSettings = outputsConstants.find(y => y.data_variable_element === x.data_variable_element);
          return outputSettings?.default_ui_visibility_pw_quote_and_sd_output === "Yes"
        });
        outputsToDisplay = outputsToDisplay.filter(x => 
          x.pw_system_quote_and_sd_order !== null);
        outputsToDisplay = outputsToDisplay.sort((a, b) => 
          a.pw_system_quote_and_sd_order - b.pw_system_quote_and_sd_order);
        
        // Schema, filter by visibility
        outputsToDisplay = outputsToDisplay.filter(x =>
          !usesSchema(sunrockConfig, x.data_variable_element) || shouldRender(sunrockConfig, x.data_variable_element, opportunityData, systemDesignData) 
        )

        let salesforceLink = 'https://sunrock.lightning.force.com/lightning/r/System_Design__c';
        if (environment === 'sandbox') {
          salesforceLink = 'https://sunrock--pw2.sandbox.lightning.force.com/lightning/r/System_Design__c';
        }
        result.push(
          <TableContainer component={Paper} sx={{
            '& .MuiTableCell-root': {
              backgroundColor: theme.palette.action.hover,
              borderRadius: 0,           
            },
          }}>
            <Table aria-label="system design table">
              <TableBody>
                <TableRow>
                  <TableCell>System Design</TableCell>
                  <TableCell align="right">
                    <Link
                      href={`${salesforceLink}/${systemDesignData.Id}/view`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Link
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        )
      } else {
        outputsToDisplay = outputs.filter(x => {
          const outputSettings = outputsConstants.find(y => y.data_variable_element === x.data_variable_element);
          return outputSettings?.default_ui_visibility_pw_quote_only_output === "Yes"
        });
        outputsToDisplay = outputsToDisplay.filter(x => 
          x.pw_quote_output_only_order !== null);
        outputsToDisplay = outputsToDisplay.sort((a, b) => 
          a.pw_quote_output_only_order - b.pw_quote_output_only_order);
        
        // Schema, filter by visibility
        outputsToDisplay = outputsToDisplay.filter(x =>
          !usesSchema(sunrockConfig, x.data_variable_element) || shouldRender(sunrockConfig, x.data_variable_element, opportunityData, systemDesignData) 
        )
      }

      if (selectedFinancingType) {
        if (selectedFinancingType === 'Lease') {
          outputsToDisplay = outputsToDisplay.filter(x => 
            x['root'] === null || (x['root'] !== null && x['root'].includes('Lease')));
        } else {
          outputsToDisplay = outputsToDisplay.filter(x => 
            x['root'] === null || (x['root'] !== null && x['root'].includes('PPA')));
        }
      }
      
      // HACK
      // Get Solver and remove it from normal disply, this we'll keep it at the top
      // with the other hard-coded-ordered elements (Offtaker and Project Id)
      const solver = outputsToDisplay.find(x => x['data_variable_element'] === "solver");
      if (solver) { 
        outputsToDisplay = outputsToDisplay.filter(x => x['data_variable_element'] !== "solver");
        if (action === 'view') {
          solver.value = selectedQuoteData[solver.data_variable_element + '__c']
        }

        result.push(
          <TableContainer component={Paper}>
          <Table aria-label="solver table">
            <TableBody>
              <TableRow>
                <TableCell>Solver</TableCell>
                <TableCell align="right">{solver.value}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        );
      }

      // More hard-code-ordered elements      
      result.push(
        <TableContainer component={Paper} sx={{
          '& .MuiTableCell-root': {
            backgroundColor: theme.palette.action.hover,
            borderRadius: 0,           
          },
        }}>
          <Table aria-label="offtaker table">
            <TableBody>
              <TableRow>
                <TableCell>Offtaker</TableCell>
                <TableCell align="right">{businessName}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
      result.push(
        <TableContainer component={Paper}>
          <Table aria-label="project id table">
            <TableBody>
              <TableRow>
                <TableCell>Project Id</TableCell>
                <TableCell align="right">{projectId}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      );
      for (let i = 0; i < outputsToDisplay?.length; i++) {
        const outputSettings = outputsConstants.find(x => x.data_variable_element === outputsToDisplay[i].data_variable_element);
        if (opportunityData && outputSettings && outputSettings.source?.includes('Opportunity')) {
          outputsToDisplay[i].value = 
            opportunityData[outputsToDisplay[i].data_variable_element + '__c']
        }
        if (systemDesignData && outputSettings && outputSettings.source?.includes('SystemDesign')) {
          outputsToDisplay[i].value = 
            systemDesignData[outputsToDisplay[i].data_variable_element + '__c']
        }
        if (action === 'view') {
          if (selectedQuoteData && outputSettings && outputSettings.source === 'Pricing Widget') {
            outputsToDisplay[i].value = 
            selectedQuoteData[outputsToDisplay[i].data_variable_element + '__c']
          }
        }
        formatValue(outputsToDisplay[i]);
        counter = counter + 1;
      }

        result.push(
          <TableContainer component={Paper}>
          <Table aria-label="dynamic outputs table">
            <TableBody>
              {outputsToDisplay.map((output, index) => (
                <TableRow 
                key={output.ui_name}
                sx={{
                  '& .MuiTableCell-root': {
                    backgroundColor: index % 2 === 0 
                      ? theme.palette.action.hover  // 'secondary' variant
                      : theme.palette.background.paper,  // 'light' variant,
                    borderRadius: 0,
                  },
                }}>
                  <TableCell>{output.ui_name}</TableCell>
                  <TableCell align="right">
                    {formatOutput(output)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        );
      const dataToExport = outputsToDisplay.map(x => {
        const { ui_name, value } = x
        return { ui_name, value };
      })
      // sr_dev_cost_per_watt not rounded
      const srDevCostPerWatt = outputsToDisplay.find(x => x.data_variable_element === 'sr_dev_cost_per_watt');
      if (srDevCostPerWatt) {
        srDevCostPerWatt.value = srDevCostPerWatt.value.toFixed(2);
      }
      dataToExport.push({ 'ui_name': 'Offtaker', 'value': businessName});
      dataToExport.push({ 'ui_name': 'Project ID', 'value': projectId});
      result.push(renderOutputButtons(dataToExport));              
    }

    return (
      <Container>{result}</Container>
    );
  }, [outputs]);

  function renderOutputButtons(dataToExport) {
    const leftButtons: ReactJSXElement[] = [];
    const rightButtons: ReactJSXElement[] = [];

    leftButtons.push(
      <CsvDownloadButton data={dataToExport}  delimiter=','
        style={{
          background:"linear-gradient(to bottom, #FF7E0B 5%, #FF7E0B 100%)",
          color: '#ffffff',
          fontSize: '15px',
          padding: '7px 24px',
          borderRadius: '4px',
          borderWidth: '0px',
          borderColor: '#FF7E0B',
          textTransform: 'uppercase',
          transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
          '&:hover': {
            backgroundColor: '#f67200',
            boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
          },
        }}
        filename={'export-' + opportunityData.Id + '.csv'} />
    )
      
      if (localStorage.getItem('email') !== null && localStorage.getItem('email') &&
        ['seth', 'qa', 'anson', 'max', 'arkady', 'luca'].filter(x => 
        localStorage?.getItem('email')?.includes(x))?.length > 0) {
          leftButtons.push(
            <Button variant='contained'
              style={{ marginLeft: '10px',
                color: 'white' }} onClick={() => 
                  handleDownloadAWSClicked()}>Download Model</Button>
          )
      }

      if (action !== 'view') {
        rightButtons.push(<Button variant='contained'
          style={{ marginLeft: '10px',
            // disabled: disableSave(),
            color: 'white' }} onClick={() => 
              handleSave()}>Save</Button>
        );
      }
      
      return (
        <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '20px'}}>
          <div>{leftButtons}</div>
          <div>{rightButtons}</div>
        </div>
      );
  }

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  return (
    <TabContext value={tab}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <TabList onChange={handleTabChange}>
          <Tab value="1" label='Outputs'/>
        </TabList>
      </Box>
      <TabPanel value="1">
      {showOutputs || action === 'view' ? renderOutputs :
        (<p className='pricing-text'>
          Fill out the fields on the left to view estimated pricing</p>)}
      </TabPanel>
      {/* <Tab eventKey="systemDesign" title="System Design">
        {systemDesignId ? renderSystemDesign() :
          (<p className='pricing-text'>No System Design attached yet.</p>)}
      </Tab> */}
      {/* {systemDesignId && environment === 'sandbox' ? (
        <Tab eventKey="systemDesignFromSF" title="SF System Design">
          {renderSystemDesignFromSF()}</Tab>) :
          (<p className='pricing-text'>No System Design found in Salesforce.</p>)}
      {opportunityId && environment === 'sandbox' ? (
        <Tab eventKey="oppFromSF" title="Opportunity">{renderOppFromSF()}</Tab>) :
          (<p className='pricing-text'>No Opportunity found.</p>)} */}
    </TabContext>
  );
}

export default Outputs;
