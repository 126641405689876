import { useState } from 'react';
import Image from 'react-bootstrap/Image';
import Nav from 'react-bootstrap/Nav';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import logo from '../../images/logo.svg';
import "./NavBar.css";
import { canSeeProjects } from '../../utils';
// TODO: get active path through navigation
function NavBar() {
  const pathName = window.location.pathname.replace("/", "");
  const initActiveLink = (pathName === "") ? "home" : pathName;
  const navigate = useNavigate();

  const [activeLink, setActiveLink] = useState(initActiveLink);

  function handleHomeClicked() {
    navigate('/');
  }

  const handleSelected = (selectedKey, event) => {
    event.preventDefault();
    // console.log("selected key is: ", selectedKey);
    setActiveLink(selectedKey);
  }


  const handleLogout = (e) => {
    localStorage.removeItem('JWT');
    localStorage.removeItem('email');
    fetch("/api/logout", {
      headers: {
        "Access-Control-Allow-Origin": "* ",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    })
    .then((res) => {
      console.log(res);
      return navigate("/login");
    })
    .catch((err) => console.log(err));
  };


  return (
    <Nav defaultActiveKey={activeLink} className='nav-bar' onSelect={(selectedKey, event) => handleSelected(selectedKey, event)}>
      <Nav.Item href="/">
        <Image src={logo} height={35} width={340} onClick={handleHomeClicked} />
      </Nav.Item>
      <Nav.Item className='nav-item-start'>
        <Nav.Link eventKey="home">
          <Link to="/" className='custom-nav-item'>Opportunities</Link>
        </Nav.Link>
      </Nav.Item>
      { canSeeProjects() &&  
        <Nav.Item className='nav-item-start'>
          <Nav.Link eventKey="projects">
            <Link to="/projects" className='custom-nav-item'>Projects</Link>
          </Nav.Link>
        </Nav.Item>
      }
      <Nav.Item className='nav-item-end'>
        <Nav.Link eventKey="logout">
          <Link onClick={() => handleLogout()} className='custom-nav-item'>Logout</Link></Nav.Link>
      </Nav.Item>
    </Nav >
  );
}

export default NavBar;