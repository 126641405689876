import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import callApiJson from './callApi';

const useCalculatorsVersion = () => useQuery({
  queryKey: ['calculatorsVersion'],
  queryFn: async () => {
    try {
      const data = await callApiJson('/api/calculators');
      return data;
    }  catch (error) {
      console.log(error);
      toast.warning('Failed to retrieve calculators, retry. Error was: ' + error.toString(), {
        position: toast.POSITION.TOP_RIGHT
      });
      throw new Error("Failed to retrieve calculators");
    }
  }
})

export default useCalculatorsVersion;