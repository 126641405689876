import { useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import callApiJson from './callApi';

const useCalculatorOutputs = (calculatorVersion: string, isView: boolean, uniqueId: string) => useQuery({
  queryKey: ["calculatorOutputs", uniqueId],
  queryFn: async () => {
    try {
      const data = await callApiJson('/api/outputs?version=' + calculatorVersion);
      return data;
    } catch (error) {
        console.log(error);
        toast.warning('Failed to retrieve outputs, retry' + error.toString(), {
          position: toast.POSITION.TOP_RIGHT
        });
    }
  },
  enabled: !!isView,
});

export default useCalculatorOutputs;
