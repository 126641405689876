/* eslint-disable no-useless-escape */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMutation } from "@tanstack/react-query";
import callApiJson from './callApi';

const environment = process.env.REACT_APP_ENVIRONMENT;

const useSaveQuote = () => useMutation({
  mutationFn: async ({ inputs, selectedSystemDesign, selectedOpportunityId, outputDestination, url }: {
    inputs: any,
    selectedSystemDesign: any,
    selectedOpportunityId: any,
    outputDestination: any,
    url: string
  }) => {
    let postData = {};
    postData = { ...inputs, ...postData };

    // remove all commas and formatting
    Object.keys(postData).map(x => {
      if (postData[x]) {
        if (postData[x].toString().indexOf(',') > -1) {
          postData[x] = parseFloat(postData[x].replace(/,/g, '')).toString();
        } else if (postData[x].toString().indexOf('%') > -1) {
          postData[x] = postData[x].slice(0,-1);
          postData[x] = (postData[x] / 100).toString();
        }
      }
    })
    postData['system_design'] = selectedSystemDesign;
    postData["opportunity_id"] = selectedOpportunityId;

    if (outputDestination !== undefined) {
      const timeStamp = outputDestination.replace(/^.*[\\\/]/, '');
      let bucketName = 'pricingwidget';
      if (environment === 'sandbox') {
        bucketName = 'pricingwidget-sandbox';
      }
      postData["s3_link"] = "https://" + bucketName + ".s3.amazonaws.com/" + timeStamp;
    }

    const data = await callApiJson(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(postData),
    });

    if (data.error) {
      throw new Error(data.error);
    }

    return data;
  }
})

export default useSaveQuote;
