import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import 'react-bootstrap-table-next/dist/react-bootstrap-table-next';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import "typeface-lato";
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.css';
import { FieldConfigProvider } from './providers/FieldConfigProvider';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { renderToast } from './constants';
import { ThemeProvider, createTheme } from '@mui/material/styles';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchIntervalInBackground: false,
      refetchInterval: false,
      staleTime: Infinity
    },
  },
})

const theme = createTheme({
  palette: {
    primary: {
      main: "#FF7E0B",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
  <BrowserRouter>
    <FieldConfigProvider>
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          {renderToast()}
          <App />
        </QueryClientProvider>
      </ThemeProvider>
    </FieldConfigProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
