import React, { useState } from "react";
import { Route, Routes } from "react-router";
import Footer from "./components/Footer/Footer";
import NavBar from "./components/NavBar/NavBar";
import Calculator from './pages/calculator/Calculator';
import Projects from './pages/projects/Projects';

import Home from './pages/home/Home';
import Signin from "./pages/login/SignIn";
import { canSeeProjects } from "./utils";
import useAuthParams from "./hooks/useAuthParams";

function App() {
  const [showNav, setShowNav] = useState(true);
  useAuthParams();
  
  return (
    <div className="App">
      {showNav &&
        <nav>
          <NavBar />
        </nav>
      }
      <Routes>
        <Route path='/' element={<Home/>} />
        {/* <Route exact path='/login' element={<Login funcNav={setShowNav}/>} /> */}
        <Route path="/login" element={<Signin showNavSetter={setShowNav}></Signin>}/>
        <Route path='/calculator' element={<Calculator/>} />
        <Route path='/calculator?:id' element={<Calculator/>} />
        { canSeeProjects() && 
          <Route path='/projects' element={<Projects/>} />
        }
      </Routes>
      {showNav &&
        <footer>
          <Footer />
        </footer>
      }
    </div>
    )
  }
  
  export default App;
  