import { GridRowId } from "@mui/x-data-grid";
import { useMutation } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { callApiBlob } from './callApi';

export const useDatatapeExport = () => useMutation({
  mutationFn: async ({ filteredSortedIds, filename }: {
    filteredSortedIds: GridRowId[],
    filename: string
  }) => {
    if (!filteredSortedIds)
      alert("No Projects selected...")

    toast.info('Download in progress...', {
      position: toast.POSITION.TOP_RIGHT,
    });
    const url =  `/api/download_projects`;

    const blob = await callApiBlob(url,
       { 
        headers : { "Content-Type": "application/json" },
        method: 'POST',
        body: JSON.stringify({project_ids: filteredSortedIds })
      });

    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.setAttribute('download', filename + ".xlsx");
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  },
  onSuccess: () => {
    toast.success('Download complete', {
      position: toast.POSITION.TOP_RIGHT
    });
  },
  onError: (err) => {
    console.log(err);
    toast.warning('Download failed, retry', {
      position: toast.POSITION.TOP_RIGHT
    });  }
});
