import { useQuery } from "@tanstack/react-query";
import callApiJson from './callApi';

export const useProjects = (searchUrl: string) => useQuery({
  queryKey: ["projects"],
  queryFn: async () => {
    try {
      const data = await callApiJson(searchUrl);
      // grab any data.project and check the coX_something max number
      // example: if co23_sr is the max function should return -> 23 
      const exampleCO = data.projects[0];
      let maxCO = 0;
      for (const key in exampleCO) {
        if (key.includes('co')) {
          const coNumber = parseInt(key.replace('co', ''));
          if (coNumber > maxCO) {
            maxCO = coNumber;
          }
        }
      }

      // filter projects to only show one per id
      const uniqueProjects = data.projects.filter((project, index) => {
        const projectId = project.project_id;
        return index === data.projects.findIndex(project => project.project_id === projectId);
      });
      return {projects: uniqueProjects, amountCOs: maxCO};
    } catch (error) {
      console.log(error);
      throw new Error("Failed to retrieve projects");
    }
  },
  enabled: !!searchUrl
});
