import { useMutation } from "@tanstack/react-query";
import callApiJson from './callApi';

export const useGoogleSignIn = () => useMutation({
  mutationFn: async () => {
    const data = await callApiJson("/api/auth/google", {
      headers: {
        "Access-Control-Allow-Origin": "* ",
        "Access-Control-Allow-Headers": "Content-Type",
      },
    });

    return data.auth_url;
  },
  onSuccess: (authUrl) => {
    window.location.assign(authUrl);
  },
  onError: (err) => {
    console.log(err);
  }
});
