import React from 'react';
import {   
  GridActionsCellItem,
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';

function titleize(str) {
  return str
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
      .join(' '); 
}

export const formatNumber = (value) => {
  if (!value) return 0;
  return new Intl.NumberFormat('en-US').format(value);
};

export const formatCONumber = (value) => {
  if (value === null || value === undefined) return null;
  if (value === 0) return 0;
  return new Intl.NumberFormat('en-US').format(value);
};

export const formatNumberToInt = (value) => {
  if (!value) return 0;
  const intValue = Math.floor(value);
  return new Intl.NumberFormat('en-US').format(intValue);
};

export const getColumnDefs = (handleRemoval, coAmount) => {
  const defs = [
    { field: 'project_name', headerName: 'Name', width: 250 , headerAlign: 'center', align: 'center'},
    { field: 'project_id', headerName: 'Project ID', width: 100, headerAlign: 'center', align: 'center' },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<DeleteIcon />}
          key={params.id}
          label="Delete"
          onClick={() => handleRemoval(params.id)}
        />,
      ],
    },
    {
      field: 'project_state',
      headerName: 'Project State',
      width: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'fund_name',
      headerName: 'Fund Name',
      width: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'financing_type',
      headerName: 'Financing Type',
      width: 250,
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ppa_term_yrs_num',
      headerName: 'PPA Term Yrs Num',
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'lease_term_yrs_num',
      headerName: 'Lease Term Yrs Num',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'ppa_escal_orig_term',
      headerName: 'PPA Escal Orig Term',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'lease_escal_orig_term',
      headerName: 'Lease Escal Orig Term',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
    },
    {
      field: 'pv_size_dc_kw_sd',
      headerName: 'PV Size Kw SD',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {field: 'kwh_per_kw_sd', headerName: 'kWh Per Kw SD', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    
    {
      field: 'installer_cost_nom',
      headerName: 'Installer Cost Nom',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'third_party_dev_cost_nom',
      headerName: 'Third Party Dev Cost Nom',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'sr_dev_cost_nom',
      headerName: 'SR Dev Cost Nom',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'sr_equip_cost_nom',
      headerName: 'SR Equip Cost Nom',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'additional_epc_cost_nom',
      headerName: 'Additional EPC Cost Nom',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'epc_total_nom_sum',
      headerName: 'EPC Total Nom Sum',
      width: 200,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {field: 'epc_total_per_watt_sd', headerName: 'EPC Total Per Watt SD', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {
      field: 'sr_trans_legal_fees_per_watt',
      headerName: 'SR Trans Legal Fees Per Watt',
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'sr_finl_dev_fee_per_watt_out',
      headerName: 'SR Finl Dev Fee Per Watt Out',
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'channel_dev_cost_per_watt',
      headerName: 'Channel Dev Cost Per Watt',
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'turnkey_nom',
      headerName: 'Turnkey Nom',
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'turnkey_per_watt',
      headerName: 'Turnkey Per Watt',
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'itc_pcnt',
      headerName: 'ITC Pcnt',
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value)
    },
    {
      field: 'itc_nom',
      headerName: 'ITC Nom',
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumber(params.value),
    },
    {
      field: 'rec_rev_nom_yr1',
      headerName: titleize('rec_rev_nom_yr1'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr2',
      headerName: titleize('rec_rev_nom_yr2'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr3',
      headerName: titleize('rec_rev_nom_yr3'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr4',
      headerName: titleize('rec_rev_nom_yr4'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr5',
      headerName: titleize('rec_rev_nom_yr5'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr6',
      headerName: titleize('rec_rev_nom_yr6'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr7',
      headerName: titleize('rec_rev_nom_yr7'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr8',
      headerName: titleize('rec_rev_nom_yr8'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr9',
      headerName: titleize('rec_rev_nom_yr9'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr10',
      headerName: titleize('rec_rev_nom_yr10'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr11',
      headerName: titleize('rec_rev_nom_yr11'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr12',
      headerName: titleize('rec_rev_nom_yr12'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr13',
      headerName: titleize('rec_rev_nom_yr13'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {
      field: 'rec_rev_nom_yr14',
      headerName: titleize('rec_rev_nom_yr14'),
      width: 250,
      type: 'number',
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatNumberToInt(params.value),
    },
    {field: 'rec_rev_nom_yr15', headerName: 'Rec Rev Nom Yr15', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumberToInt(params.value)},
    {field: 'rec_rev_nom_yr16', headerName: 'Rec Rev Nom Yr16', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumberToInt(params.value)},
    {field: 'rec_rev_nom_yr17', headerName: 'Rec Rev Nom Yr17', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumberToInt(params.value)},
    {field: 'rec_rev_nom_yr18', headerName: 'Rec Rev Nom Yr18', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumberToInt(params.value)},
    {field: 'rec_rev_nom_yr19', headerName: 'Rec Rev Nom Yr19', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumberToInt(params.value)},
    {field: 'rec_rev_nom_yr20', headerName: 'Rec Rev Nom Yr20', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumberToInt(params.value)},
    {field: 'pv_fcst_prod_yr1_jan_1', headerName: 'PV Fcst Prod Yr1 Jan 1', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value),},
    {field: 'pv_fcst_prod_yr1_feb_2', headerName: 'PV Fcst Prod Yr1 Feb 2', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_mar_3', headerName: 'PV Fcst Prod Yr1 Mar 3', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_apr_4', headerName: 'PV Fcst Prod Yr1 Apr 4', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_may_5', headerName: 'PV Fcst Prod Yr1 May 5', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_jun_6', headerName: 'PV Fcst Prod Yr1 Jun 6', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_jul_7', headerName: 'PV Fcst Prod Yr1 Jul 7', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_aug_8', headerName: 'PV Fcst Prod Yr1 Aug 8', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_sep_9', headerName: 'PV Fcst Prod Yr1 Sep 9', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_oct_10', headerName: 'PV Fcst Prod Yr1 Oct 10', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_nov_11', headerName: 'PV Fcst Prod Yr1 Nov 11', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'pv_fcst_prod_yr1_dec_12', headerName: 'PV Fcst Prod Yr1 Dec 12', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'opp_maintain_cost_escal', headerName: 'Opp Maintain Cost Escal', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'opp_maintain_cost_yr1_per_kw', headerName: 'Opp Maintain Cost Yr1 Per Watt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'opp_maintain_cost_yr6_per_kw', headerName: 'Opp Maintain Cost Yr6 Per Watt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'opp_maintain_cost_yr11_per_kw', headerName: 'Opp Maintain Cost Yr11 Per Watt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'dollars_kwh_yr1_ppa_renewal_period', headerName: 'Dollars kWh Yr1 Ppa Renewal Period', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'ppa_escal_renewal_period', headerName: 'PPA Escal Renewal Period', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'lease_escal_renewal_period', headerName: 'Lease Escal Renewal Period', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'avoided_cost_per_kwh_sd', headerName: 'Avoided Cost Per kWh SD', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'disc_to_pre_system_utility_bill_pcnt_out', headerName: 'Disc To Pre System Utility Bill Pcnt Out', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'dollars_kwh_yr1_ppa', headerName: 'Dollars kWh Yr1 Ppa', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'dollars_kwh_yr1_lease', headerName: 'Dollars kWh Yr1 Lease', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'contract_tranche_ready', headerName: 'Contract Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'contract_tranche_submit_sale_one', headerName: 'Contract Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'contract_tranche_approval_sale_one', headerName: 'Contract Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'contract_tranche_pcnt_sale_one', headerName: 'Contract Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'contract_tranche_nom_sale_one', headerName: 'Contract Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_contract_dev_pcnt', headerName: 'Milestone Contract Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_contract_dev_nom', headerName: 'Milestone Contract Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'ntp_tranche_ready', headerName: 'NTP Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'ntp_tranche_submit_sale_one', headerName: 'NTP Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'ntp_tranche_approval_sale_one', headerName: 'NTP Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'ntp_tranche_pcnt_sale_one', headerName: 'NTP Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'ntp_tranche_nom_sale_one', headerName: 'NTP Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_ntp_dev_pcnt', headerName: 'Milestone NTP Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_ntp_dev_nom', headerName: 'Milestone NTP Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'procure_tranche_ready', headerName: 'Procure Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'procure_tranche_submit_sale_one', headerName: 'Procure Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'procure_tranche_approval_sale_one', headerName: 'Procure Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'procure_tranche_pcnt_sale_one', headerName: 'Procure Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'procure_tranche_nom_sale_one', headerName: 'Procure Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_procure_dev_pcnt', headerName: 'Milestone Procure Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_procure_dev_nom', headerName: 'Milestone Procure Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'commence_tranche_ready', headerName: 'Commence Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'commence_tranche_submit_sale_one', headerName: 'Commence Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'commence_tranche_approval_sale_one', headerName: 'Commence Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'commence_tranche_pcnt_sale_one', headerName: 'Commence Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'commence_tranche_nom_sale_one', headerName: 'Commence Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_start_of_construct_dev_pcnt', headerName: 'Milestone Start of Construct Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_start_of_construct_dev_nom', headerName: 'Milestone Start of Construct Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'midpoint_tranche_ready', headerName: 'Midpoint Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'midpoint_tranche_submit_sale_one', headerName: 'Midpoint Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'midpoint_tranche_approval_sale_one', headerName: 'Midpoint Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'midpoint_tranche_pcnt_sale_one', headerName: 'Midpoint Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'midpoint_tranche_nom_sale_one', headerName: 'Midpoint Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_midpoint_dev_pcnt', headerName: 'Milestone Midpoint Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_midpoint_dev_nom', headerName: 'Milestone Midpoint Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)}, 
    {field: 'mc_tranche_ready', headerName: 'MC Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'mc_tranche_submit_sale_one', headerName: 'MC Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'mc_tranche_approval_sale_one', headerName: 'MC Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'mc_tranche_submit_sale_two', headerName: 'MC Tranche Submit Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'mc_tranche_approval_sale_two', headerName: 'MC Tranche Approval Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'mc_tranche_pcnt_sale_one', headerName: 'MC Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'mc_tranche_nom_sale_one', headerName: 'MC Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'mc_tranche_pcnt_sale_two', headerName: 'MC Tranche Pcnt Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'milestone_mech_comp_dev_pcnt', headerName: 'Milestone Mech Comp Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'milestone_mech_comp_dev_nom', headerName: 'Milestone Mech Comp Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'sc_tranche_ready', headerName: 'SC Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'sc_tranche_submit_sale_one', headerName: 'SC Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'sc_tranche_approval_sale_one', headerName: 'SC Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'sc_tranche_submit_sale_two', headerName: 'SC Tranche Submit Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'sc_tranche_approval_sale_two', headerName: 'SC Tranche Approval Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'}, 
    {field: 'sc_tranche_pcnt_sale_one', headerName: 'SC Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'sc_tranche_nom_sale_one', headerName: 'SC Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'sc_tranche_pcnt_sale_two', headerName: 'SC Tranche Pcnt Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'milestone_sub_comp_dev_pcnt', headerName: 'Milestone Sub Comp Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'milestone_sub_comp_dev_nom', headerName: 'Milestone Sub Comp Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'fc_tranche_ready', headerName: 'FC Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'fc_tranche_submit_sale_one', headerName: 'FC Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'fc_tranche_approval_sale_one', headerName: 'FC Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'fc_tranche_pcnt_sale_one', headerName: 'FC Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'fc_tranche_nom_sale_one', headerName: 'FC Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_final_comp_dev_pcnt', headerName: 'Milestone Final Comp Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'milestone_final_comp_dev_nom', headerName: 'Milestone Final Comp Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center', renderCell: (params) => formatNumber(params.value)},
    {field: 'cod_tranche_ready', headerName: 'COD Tranche Ready', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'cod_tranche_submit_sale_one', headerName: 'COD Tranche Submit Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'cod_tranche_approval_sale_one', headerName: 'COD Tranche Approval Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'cod_tranche_submit_sale_two', headerName: 'COD Tranche Submit Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'cod_tranche_approval_sale_two', headerName: 'COD Tranche Approval Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'cod_tranche_pcnt_sale_one', headerName: 'COD Tranche Pcnt Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'cod_tranche_nom_sale_one', headerName: 'COD Tranche Nom Sale One', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'cod_tranche_pcnt_sale_two', headerName: 'COD Tranche Pcnt Sale Two', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'milestone_cod_dev_pcnt', headerName: 'Milestone Cod Dev Pcnt', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'milestone_cod_dev_nom', headerName: 'Milestone Cod Dev Nom', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    {field: 'support_structure_type_primary_sd', headerName: 'Support Structure Type Primary SD', width: 250, type: 'number', headerAlign: 'center', align: 'center'},
    ...Array.from({ length: coAmount }, (_, i) => ([
      { // installer_cost_nom
        field: `co${i + 1}_installer_cost_nom`,
        headerName: `CO ${i + 1} Installer Cost Nom`,
        width: 250,
        headerAlign: 'center',
        align: 'center',
        renderCell: (params) => formatCONumber(params.value),
      },
      {
      field: `co${i + 1}_additional_epc_cost_nom`,
      headerName: `CO ${i + 1} Additional EPC Cost Nom`,
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatCONumber(params.value),
    }, 
    { //sr_equip_cost_nom
      field: `co${i + 1}_sr_equip_cost_nom`,
      headerName: `CO ${i + 1} SR Equip Cost Nom`,
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatCONumber(params.value),
    },
    { // sr_dev_cost_nom
      field: `co${i + 1}_sr_dev_cost_nom`,
      headerName: `CO ${i + 1} SR Dev Cost Nom`,
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatCONumber(params.value),
    },
    { //third_party_dev_cost_nom
      field: `co${i + 1}_third_party_dev_cost_nom`,
      headerName: `CO ${i + 1} Third Party Dev Cost Nom`,
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatCONumber(params.value),
    },
    {
      field: `co${i + 1}_epc_total_nom_sum`,
      headerName: `CO ${i + 1} EPC Total Nom Sum`,
      width: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => formatCONumber(params.value),
    },
  ])).flat(),
  ];

  return defs;
}