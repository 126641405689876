
function usesSchema(config, fieldName) {
    const recField = config.v2Fields.find((recField) => recField.name === fieldName)
    return recField ? true : false;
}

function getFieldDefinition(config, fieldName) {
    const recField = config.v2Fields.find((recField) => recField.name === fieldName)
    
    if (!recField) {
        throw new Error(`getFieldDefinition: field, ${fieldName} , not found`);
    }

    return recField;
}

function getFieldNote(config, fieldName) {
    const recField = getFieldDefinition(config, fieldName);
    return recField.note;
}

function passesFlowRestrictions(recField, systemDesignData) {
        
    if (recField.flowRestrictions === undefined) {
        return true;
    }

    for (const flowRestriction of recField.flowRestrictions) {
        switch (flowRestriction) {
            case 'requires-system-design':
                
                if (!systemDesignData) {
                    return false;
                }

                break;
            case 'quote-only':
                if (systemDesignData) {
                    return false;
                }
                break;
            default:
                throw Error("Unsupport flowRestriction")
        }
    }

    return true;

}

function shouldRender(config, fieldName, opportunity, systemDesignData, formData) {
    

   

    let shouldRender = false;
    const recField = getFieldDefinition(config, fieldName)
     
    if (!passesFlowRestrictions(recField, systemDesignData)) {
        return false;   
    }

    if (recField && recField.name === fieldName) {
        if ('statesApplicable' in recField) {
            if (recField.statesApplicable.includes(opportunity.project_state__c)) {
                shouldRender = true;
            }
        } else {
            if (recField.showConditions) {
                for (const showCondition of recField.showConditions) {
                    
                    // tmp hack, 
                    // this ar isn't there on outputs
                    if (!formData) {
                        console.log("formData empty, return true")
                        return true
                    }
                    // tmp - not efficient, build dict
                    const match = formData.find((field) => field.data_variable_element === showCondition.field)
                    if (match.value !== showCondition.value) {
                        return false;
                    }

                }
            }
            shouldRender = true;
        }

        return shouldRender;

    }
    
    return shouldRender;
}

function getDecimalPlaces(decimalString) {
    const match = /decimal\s?\((\d+)\s?,\s?(\d+)\)/i.exec(decimalString);
    let result = 3;
    if (match) {
        console.log("match", match)
        result = parseInt(match[2], 10);
    } 
    return result;
}

export { 
    shouldRender, 
    getFieldDefinition, 
    passesFlowRestrictions, 
    usesSchema,
    getDecimalPlaces,
    getFieldNote
};