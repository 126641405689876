import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const useAuthParams = () => {
  const nav = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const email = query.get('email');
    if (email) {
      localStorage.setItem('email', email);
    }
  }, [nav]);
};

export default useAuthParams;