import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { Button } from '@mui/material';
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';

import './CustomModal.css';
import { toast } from 'react-toastify';

function CustomModal(props) {
  const navigate = useNavigate();
  const [systemDesigns, setSystemDesigns] = useState([]);
  const [selectedSystemDesign, setSelectedSystemDesign] = useState();

  const handleCreate = () => {
    console.log(props.projectState);
    if (props.projectState === undefined || props.projectState === null) {
      let errorMsg = "A project address is required in the opportunity to create a quote.  " + 
        "Please enter a project address in the associated opportunity";
      toast.warning(errorMsg, {
        position: toast.POSITION.TOP_RIGHT
      });
    } else {
      navigate('/calculator?opportunityId=' + props.opportunityId + 
      "&projectId=" + props.applicationId + 
      '&businessName=' + props.businessName + 
      (selectedSystemDesign !== undefined && selectedSystemDesign.length >  0 ? 
        "&systemDesign=" + selectedSystemDesign : ""));
    }
  }

  const handleSelectedSystemDesign = (e) => {
    const selected = systemDesigns.filter(x => x.name === e.target.value);
    if (selected && selected.length) {
      setSelectedSystemDesign(selected[0].id);
    } else {
      setSelectedSystemDesign(undefined);
    }
  }

  useEffect(() => {
    if (props.opportunityId !== undefined) {
      // console.log(props.opportunityId);
      fetch("/api/system-designs?opportunityId=" + props.opportunityId)
      .then((res => res.json()))
      .then(data => {
        data.map(x => {
          x.opportunityId = x.opportunity__c;
          x.name = x.Name;
          x.id = x.Id;
          x.projectState = x.project_state__c;
        });
        setSystemDesigns(data);
      }).catch(error => {
        console.log(error);
      })
    }
  }, [props.opportunityId]);


  function renderOptions() {
    if (systemDesigns && systemDesigns.length) {
      let result = [<option>None</option>];
      for (let i = 0; i < systemDesigns.length; i++) {
        result.push(
          <option>
            {systemDesigns[i].name}
          </option>
        )
      }
      return result;
    }
    return (
      <option>None</option>
    )
  }

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
        </Modal.Title>
      </Modal.Header>
      <h2 className='modal-heading'>Generating SQ for {props.applicationId} {props.businessName}</h2>
      <Modal.Body className='modal-custom-body'>
        <h4 className='modal-subheading'>System Design</h4>
        <Form.Select style={{ width: '380px' }} onChange={handleSelectedSystemDesign}>
          {renderOptions()}
        </Form.Select>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="outlined" onClick={props.onHide}>Cancel</Button>
        <Button variant="contained" style={{ color: 'white', marginLeft: '10px' }} onClick={handleCreate}>Create Sales Quote</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default CustomModal;