import { createContext, useContext, useState, useEffect } from 'react';

const FieldConfigContext = createContext();

export function useJsonData() {
  return useContext(FieldConfigContext);
}

export function FieldConfigProvider({ children }) {
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/api/field-config')
      .then((response) => response.json())
      .then((data) => {
        setJsonData(data);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <FieldConfigContext.Provider value={jsonData}>
      {children}
    </FieldConfigContext.Provider>
  );
}
