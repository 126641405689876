import React from 'react';
import { Select, MenuItem, FormControl, InputLabel } from '@mui/material';

function CalculatorVersionSelector({ availableCalcs, calculatorVersion, setSelectedCalculatorVersion, action }: {
  availableCalcs: string[],
  calculatorVersion: string,
  setSelectedCalculatorVersion: (version: string) => void,
  action: string
}) {
  if (!availableCalcs) {
    return null;
  }

  const options = availableCalcs.map(x => x['version'].replace('.xlsm', ''));

  const handleChange = (event) => {
    setSelectedCalculatorVersion(event.target.value);
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="calculator-version-label">Calculator Version</InputLabel>
      <Select
        labelId="calculator-version-label"
        id="calculator-version-select"
        value={calculatorVersion}
        label="Calculator Version"
        onChange={handleChange}
        disabled={['view', 'edit'].includes(action)}
      >
        {options.map((option) => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default CalculatorVersionSelector;
