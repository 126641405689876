import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import './CustomModal.css';

function UploadModal(props) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                </Modal.Title>
            </Modal.Header>
            <h2 className='modal-heading'>Do you want to upload this custom quote?</h2>
            {props.fileName ? (<Button disabled variant='link'>{props.fileName}</Button>): undefined}
            <Modal.Footer style={{ marginTop: '10px' }}>
                <Button variant="outline-success" onClick={props.onHide}>No, cancel</Button>
                <Button variant="success" onClick={props.onUpload}>Yes, upload</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default UploadModal;