import React from 'react';
import { useField } from 'formik';
import { FormControl, InputLabel, Select, MenuItem, Typography } from '@mui/material';

interface Option {
  value: string | number;
  label: string;
}

interface SelectorFieldProps {
  name: string;
  label: string;
  options: Option[];
  defaultValue?: string;
  isViewOnly?: boolean;
  isRequired?: boolean;
  isTouched?: boolean;
}

const SelectorField: React.FC<SelectorFieldProps> = ({ name, label, options, isViewOnly = false, defaultValue = false, isRequired = false, isTouched = false }) => {
  const [field, meta, helpers] = useField(name);
  if (defaultValue && field.value === undefined) {
    field.onChange(defaultValue);
  }

  if (
    (field.name === "ppa_term_yrs_num" || field.name === "ppa_escal_orig_term" || field.name === "lease_term_yrs_num" || field.name === "lease_escal_orig_term") 
    && (field.value === undefined || field.value === null || field.value === "")) {
      helpers.setValue(defaultValue);
  }

  const oneOfFinance = field.name === "ppa_term_yrs_num" || field.name === "lease_term_yrs_num" || field.name === "ppa_escal_orig_term" || field.name === "lease_escal_orig_term";
  return (
    <FormControl disabled={isViewOnly} fullWidth margin="normal">
      <InputLabel sx={{ fontWeight: 'bold', color: (!isRequired || (defaultValue && !oneOfFinance) || isTouched || meta.touched) ? 'black' : '#FF7E0B' }} id={`${name}-label`}>{label}</InputLabel>
      <Select
        {...field}
        labelId={`${name}-label`}
        id={name}
        label={label}
        value={field.value || defaultValue}
        name={field.name}
        disabled={isViewOnly}
        onChange={(event) => {
          helpers.setValue(event.target.value);
        }}
        onBlur={() => helpers.setTouched(true)}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
      {((meta.touched && meta.error)) && (
        <Typography color="error">{meta.error}</Typography>
      )}
    </FormControl>
  );
};

export default SelectorField;
