import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import './LoginLogout.css';
import { Image, Card, Nav } from "react-bootstrap";
import { useGoogleSignIn } from "../../api/useGoogleSignIn";
import Button from '@mui/material/Button';

const logo: string = require('../../images/logo.svg').default;

function SignIn({ showNavSetter }: {
  showNavSetter: (show: boolean) => void;
}) {
  const nav = useNavigate();
  const googleSignIn = useGoogleSignIn();
  showNavSetter(false);

  useEffect(() => {
    if(localStorage.getItem('JWT') !== null){
        showNavSetter(true);
        return nav("/")
    }
  });
  
  const handleClick = async () => {
    await googleSignIn.mutateAsync();
  };

  return (
    <div className="center-card">
        <Card style={{ height: '220px', border: '20px', marginTop: '20px'}}>
        <Card.Title>
            <Nav.Item className="center-image">
              <Nav.Link href="/">
                <Image src={logo} height={35} width={340}/>
              </Nav.Link>
            </Nav.Item>
        </Card.Title>
        <Card.Body>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            <Button 
              variant="contained" 
              onClick={handleClick} 
              disabled={googleSignIn.isPending}
              className="loginButton"
              >
                <Image src="google.svg" alt="google login" className="loginIcon"></Image>
                <span className="loginButtonText">Sign in with Google</span>
            </Button>
          </div>
        </Card.Body>
        </Card>
    </div>
  );
}

export default SignIn;